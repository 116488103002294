import React, { useEffect } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import ChangePassword from "../Pages/ChangePassword";
import FAQs from "../Pages/FAQs";
import ResetPassword from "../Pages/ResetPassword";
import SignIn from "../Pages/SignIn";
import history from "./history";
import { connect } from "react-redux";
import ReallyMovingLayout from "../ReusableComponents/ReallyMovingLayout";
const Home = React.lazy(() => import("../Pages/Home"));
const StartingSteps = React.lazy(() => import("../Pages/StartingSteps"));
const Step6 = React.lazy(() => import("../ReusableComponents/Step6"));
const DashboardLayout = React.lazy(() =>
  import("../ReusableComponents/DashboardLayout")
);
const PaymentLayout = React.lazy(() => import("../Pages/PaymentSteps"));
const PrivateRoute = React.lazy(() => import("./private"));
const AddMoreServices = React.lazy(() => import("../Pages/AddMoreServices"));
const PrivacyPolicy = React.lazy(() => import("../Pages/PrivacyPolicy"));
const TermsCondition = React.lazy(() => import("../Pages/TermsCondition"));
const CookiePolicy = React.lazy(() => import("../Pages/CookiePolicy"));
const ContactUs = React.lazy(() => import("../Pages/ContactUs"));
const Blog = React.lazy(() => import("../Pages/MainBlogPage"));
const Blog1 = React.lazy(() => import("../Pages/Blogs/Blog1"));
const Blog2 = React.lazy(() => import("../Pages/Blogs/Blog2"));
const Blog3 = React.lazy(() => import("../Pages/Blogs/Blog3"));
const Blog4 = React.lazy(() => import("../Pages/Blogs/Blog4"));
const Blog5 = React.lazy(() => import("../Pages/Blogs/Blog5"));
const VerifyWithYoti = React.lazy(() => import("../Pages/Yoti/verifyWithYoti"));
const YotiSuccess = React.lazy(() => import("../Pages/Yoti/yotiSuccess"));
const YotiFailure = React.lazy(() => import("../Pages/Yoti/yoti-failure"));
const YotiProcess = React.lazy(() => import("../Pages/Yoti/yotiProcess"));
const YotiVerificationByUs = React.lazy(() =>
  import("../Pages/Yoti/yotiVerifying")
);

const MoveAgain = React.lazy(() => import("../Pages/MoveAgain"));

const Routes = (props) => {
  useEffect(() => {
    // TokenValidation();
  }, []);

  return (
    <Router history={history}>
      <ReallyMovingLayout>
        <Switch>
          <PrivateRoute exact path="/dashboard" Component={DashboardLayout} />
          <Route path="/" exact render={() => <Redirect to="/getStarted" />} />
          <Route path="/login" exact component={SignIn} />
          <Route path="/reset-password" exact component={ResetPassword} />
          <Route
            path="/change-password/:token"
            exact
            component={ChangePassword}
          />

          <Route path="/home/:token" exact component={Home} />
          <PrivateRoute
            path="/addMoreServices"
            exact
            Component={AddMoreServices}
          />
          <PrivateRoute
            path="/yoti-process/:sessionId/:clientId"
            exact
            Component={YotiProcess}
          />

          <Route path="/getStarted" exact component={StartingSteps} />
          <Route path="/getStarted/:id" exact component={StartingSteps} />
          <Route path="/moveAgain" exact component={MoveAgain} />
          <Route path="/FAQ" exact component={FAQs} />
          {/* <PrivateRoute path="/verify-yoti" exact Component={VerifyWithYoti} />
    <PrivateRoute
     path="/yoti-verifying"
     exact
     Component={YotiVerificationByUs}
    /> */}

          {/* <PrivateRoute path="/yoti-success" exact Component={YotiSuccess} />
    <PrivateRoute path="/yoti-failure" exact Component={YotiFailure} /> */}

          <Route path="/payment" exact component={PaymentLayout} />
          <Route path="/privacy-policy" exact component={PrivacyPolicy} />
          <Route path="/terms-condition" exact component={TermsCondition} />
          <Route path="/cookie-policy" exact component={CookiePolicy} />
          <Route path="/contact-us" exact component={ContactUs} />

          <Route path="/blogs" exact component={Blog} />
          <Route
            path="/blogs/10-things-to-consider-when-choosing-your-new-home"
            exact
            component={Blog1}
          />
          <Route
            path="/blogs/the-importance-of-notifying-your-change-of-address"
            exact
            component={Blog2}
          />
          <Route
            path="/blogs/7-good-questions-to-ask-when-moving-in"
            exact
            component={Blog3}
          />
          <Route
            path="/blogs/10-simple-ways-to-make-your-home-more-eco-friendly"
            exact
            component={Blog4}
          />
          <Route
            path="/blogs/10-packing-hacks-that-will-help-with-your-move"
            exact
            component={Blog5}
          />

          <Route path="*" render={() => <h1>Not Found</h1>}></Route>
        </Switch>
      </ReallyMovingLayout>
    </Router>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps)(Routes);
